import { FC } from 'react'

import { TrashIcon } from '@heroicons/react/24/outline'

import Button from 'src/components/Library/Button/Button'
import { useConfirm } from 'src/lib/hooks/Confirmation/index'

import { Asset } from '../types'

interface AssetLibraryItemProps {
  asset: Asset
  deleteAssetItem: any
  isDeleting: boolean
}

const AssetLibraryItem: FC<AssetLibraryItemProps> = ({
  asset,
  deleteAssetItem,
  isDeleting,
}) => {
  const { fileName, id, downloadUrl } = asset
  const confirm = useConfirm()

  return (
    <div className="overflow-hidden rounded-lg bg-white p-2 shadow">
      <div className="flex w-full items-center justify-between gap-4">
        <p className="rounded bg-indigo-100 p-1 text-xs text-indigo-600">
          {fileName}
        </p>
        <Button
          variant="text"
          fullWidth={false}
          disabled={isDeleting}
          className="!px-0"
          onClick={async (e) => {
            e.preventDefault()

            const isConfirmed = await confirm({
              description: 'are you sure you want to delete this asset?',
              allowClose: true,
            })
            if (isConfirmed) {
              /* yes selected */
              deleteAssetItem(id)
            }
          }}
        >
          <TrashIcon className="h-4 w-4 text-red-500" />
        </Button>
      </div>
      <div className="grid h-full grow place-items-center">
        <img
          src={downloadUrl}
          alt=""
          width="100"
          height="100"
          className="object-contain pb-6 pt-8"
        />
      </div>
    </div>
  )
}

export default AssetLibraryItem
